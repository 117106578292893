@media print { /**/
  /*
    Need to wrap the text inside the table cell that we want to display
    vertically in an additional span (see also <PolicyRule>) because of
    two bugs:

    1. In Firefox, there doesn't seem to be a way to control the horizontal
    alignment of vertical text in a table cell, since `text-align: center` will
    align the text vertically.

    2. In Chrome and Edge, vertical text in a table cell becomes horizontal text
    when printing (but this problem does not happen with vertical text in a span
    in a table cell).

    2b. In Safari, vertical text in a table cell does not work.
   */
  .PolicyEditor-table tr td.element_group_name > span {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    text-align: center;
    display: block;
    margin: auto;
    height: min-content;
  }

  .PolicyEditor-table {
    max-width: 7.5in;
  }

  .toolbar {
    display: none;
  }

  /* don't show anything that's marked as only useful for the interactive webpage experience */
  .media-screen {
    display: none !important;
  }

  button {
    display: none;
  }

  select {
    border: 0;
    background: none;
  }

  .collapsible-box-title .material-icons-outlined {
    display: none;
  }

  .policy-header-container > div {
    border: 0 !important;
  }

  .policy-header-container h3 {
    text-align: center;
  }

  .media-print.attribute-value {
    padding: 1px 4px;
    border-radius: 2px;
    box-sizing: border-box;
    display: inline-block;
  }

} /**/

@media screen {
  .media-print {
    display: none;
  }
}

ul {
  padding-left: 2em;
}

table {
  border-collapse: collapse;
  width: 100%;
}

.App {
  margin-left: auto;
  margin-right: auto;
  min-width: 500px;
  width: max-content;
  font-size: 0.8em;
  padding-bottom: 3em;
  margin-bottom: 3em;
}

.App h1,
.App h2 {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/*
 * Styles for PolicyList
 */

.policy-list-table {
  margin-top: 1px;
  border-collapse: collapse;
}

.policy-list-table tr {
  cursor: pointer;
}

.policy-list-table tr:hover {
  background-color: #eef;
}

.policy-list-table td {
  border-width: .5px 0;
}

/*
 * Styles for PolicyEditor
 */

 /* bit of a hacky way to hide PolicyEditor UI without destroying it to save on rerender cost */
.PolicyEditor.loading > div {
  opacity: 0;
}

.PolicyEditor.loading table {
  display: none;
}

.PolicyEditor.loading .LoadingSpinner {
  opacity: 1;
}

.PolicyEditor-table {
  border-collapse: collapse;
}

.PolicyEditor-table thead td {
  border: 0;
}

.PolicyEditor-wrapper input[type="text"],
.PolicyEditor-wrapper textarea {
  width: 90%;
}

.compare-list {
  white-space: unset;
}

.org-type-badge {
  display: inline-block;
  padding: 0 3px;
  border-radius: 3px;
  border: 0;
  margin-right: 3px;
  background: #eee;
  color: black;
}

.spacercol {
  border-top: 0px;
  border-bottom: 0px;
}

.PolicyEditor-table th {
  border-width: 5px 2px;
  text-transform: uppercase;
  color: white;
  background-color: black;
}

.PolicyEditor-table .material-icons-outlined {
  user-select: none;
}

.PolicyEditor-table td {
  white-space: nowrap;
}

.PolicyEditor-table .element_group_name {
  width: min-content;
  height: min-content;
  white-space: unset;
}

/*
 * Button toolbars
 */

button .material-icons-outlined {
  font-size: 1.3em;
  margin-right: .3em;
  vertical-align: bottom; /* TODO: is this the right way to align things? */
}

button.small-unlabeled {
  font-size: 0.8em;
  padding: 1px;
}

button.small-unlabeled .material-icons-outlined {
  margin: 0;
}

.toolbar {
  font-size: 15px;
  margin-bottom: 1em;
}

.toolbar button {
  padding: 6px;
}

/*
 * Make modals not take up the entire screen width - can make the UI hard to read
 */
.ReactModal__Content {
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  min-width: 33%;
  max-width: 80%;
}

/*
 * TLDListSelector styles
 */

.TLDListSelector-table tr:hover {
  background-color: #eef;
  cursor: pointer;
}
